window.addEventListener('DOMContentLoaded', function () {
  var playground;

  function select_playground() {
    document.querySelectorAll('[data-playground]').forEach(function (element) {
      element.hidden = true
    })

    var selected_account = document.querySelector('select#playground_account').value;
    document.querySelector('[data-playground="' + selected_account + '"]').hidden = false;
    document.querySelector('[data-playground-account-id]').innerHTML = selected_account;
  };

  if (playground = document.querySelector('select#playground_account')) {
    playground.addEventListener('change', select_playground);
    select_playground();
  }
})
