document.addEventListener('click', function(event) {
  var link = event.target.closest('.js-toggle-metadata-link')
  if (!link) return

  event.preventDefault()

  if (link.classList.contains('js-toggle-metadata-hidden')) {
    link.innerText = 'Show details'
  } else {
    link.innerText = 'Hide Details'
  }

  link.classList.toggle('js-toggle-metadata-hidden')

  var session = link.closest('.session')
  if (session) {
    session.querySelectorAll('.js-toggle-metadata').forEach(function(element) {
      element.hidden = !element.hidden
    })
  }
})
