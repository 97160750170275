/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs';
Rails.start();

import '../components/error_tracking.js';
import '../components/accounts.js';
import '../components/flash.js';
import '../components/forms.js';
import '../components/security.js';
import '../components/tips.js';
import '../components/authorizations.js';
import '../components/playground.js';
import '../components/detect_timezone.js';
import '../components/button_loading.js';

// Safely open windows in new tab by setting opener to null
// Replaces target=_blank
document.addEventListener('click', function(evt) {
  if (!evt.target.closest('.js-open-in-new-window')) return;
  evt.preventDefault();
  var newWindow = window.open(evt.target.href);
  if (newWindow) newWindow.opener = null;
});
