window.addEventListener('DOMContentLoaded', function() {
  var formButtons = document.querySelectorAll('form button')

  formButtons.forEach(function(button) {
    button.disabled = false
  })

  var forms = document.querySelectorAll('form')

  forms.forEach(function(form) {
    form.addEventListener('submit', function(event) {
      var button = event.target.querySelector('button')
      if (button) button.disabled = true
    })
  })
})
