const AuthorizationAccountChooser = class AuthorizationAccountChooser {
  constructor() {
    this.setupHandlers = this.setupHandlers.bind(this);
    this.openEvent = this.openEvent.bind(this);
    this.closeEvent = this.closeEvent.bind(this);
    this.openLink = document.querySelector('[data-account-chooser=open]');
    this.closeLink = document.querySelector('[data-account-chooser=close]');
    this.description = document.querySelector('[data-account-chooser=description]');
    this.form = document.querySelector('[data-account-chooser=form]');
    this.checkBoxes = this.form.querySelectorAll('input');
    this.setupHandlers();
  }

  setupHandlers() {
    this.openLink.addEventListener('click', this.openEvent);
    this.closeLink.addEventListener('click', this.closeEvent);
  }

  openEvent(event) {
    event.preventDefault();
    this.description.querySelector('[data-account-chooser=before-open]').hidden = true;
    this.description.querySelector('[data-account-chooser=after-open]').hidden = false;
    this.form.hidden = false;
    this.checkBoxes.forEach(function (checkBox) {
      checkBox.removeAttribute('disabled');
    });
  }

  closeEvent(event) {
    event.preventDefault();
    this.description.querySelector('[data-account-chooser=before-open]').hidden = false;
    this.description.querySelector('[data-account-chooser=after-open]').hidden = true;
    this.form.hidden = true;
    this.checkBoxes.forEach(function (checkBox) {
      checkBox.disabled = 'disabled';
    });
  }
};

window.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('[data-account-chooser=form]')) {
    new AuthorizationAccountChooser();
  }
});
