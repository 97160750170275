window.addEventListener('DOMContentLoaded', function() {
  var hidden_accounts, no_accounts_message, remove_button, reveal_button

  remove_button = document.getElementById('remove-hidden-accounts')
  reveal_button = document.getElementById('reveal-hidden-accounts')
  no_accounts_message = document.querySelector('.no-accounts')
  hidden_accounts = document.querySelectorAll('ul.accounts li.account[hidden]')

  if (remove_button) {
    remove_button.hidden = true
    remove_button.addEventListener('click', function(event) {
      event.preventDefault()

      hidden_accounts.forEach(function(element) {
        element.hidden = true
      })
      if (remove_button != null) {
        remove_button.hidden = true
      }
      if (reveal_button != null) {
        reveal_button.hidden = false
      }
      if (no_accounts_message != null) {
        no_accounts_message.hidden = false
      }
    })
  }

  if (reveal_button) {
    reveal_button.addEventListener('click', function(event) {
      event.preventDefault()

      hidden_accounts.forEach(function(element) {
        element.hidden = false
      })
      if (remove_button != null) {
        remove_button.hidden = false
      }
      if (reveal_button != null) {
        reveal_button.hidden = true
      }
      if (no_accounts_message != null) {
        no_accounts_message.hidden = true
      }
    })
  }
})
