document.addEventListener('click', function(event) {
  var tip, token;
  if (event.target.closest('.js-dismiss-tip')) {
    event.preventDefault();
    token = document.querySelector('meta[name="csrf-token"]').content;
    tip = event.target.closest(".js-tip");
    tip.hidden = true;
    fetch('/dismissed_tips', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        name: tip.dataset.tipName
      }),
      credentials: 'same-origin'
    });
  }
});
